@import './normalize.css';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #242424;
  list-style: none;
  scroll-margin-top: 80px;
}

*:focus {
  outline: none;
}

*:focus-visible {
  outline: 3px dashed var(--navy-600, currentColor);
  outline-offset: 4px;
  border-radius: 4px;
}

input:focus-visible {
  outline: unset;
}

p + p {
  margin-top: 16px;
}

strong {
  font-weight: unset;
  color: var(--light-blue-500, #0376be) !important;
}

p,
li,
span,
a,
button {
  font-size: clamp(16px, calc(20vw / 7.68), 20px);
  line-height: 150%;
  letter-spacing: 0.3px;

  @media (max-width: 480px) {
    line-height: 160%;
    letter-spacing: 0.24px;
  }
}

p, span{
  strong {
    font-weight: 700;
    color: #242424 !important;
  }
}

.p,
.p > * {
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.24px;
}

h1,
.h1,
.h1 > * {
  font-size: clamp(32px, calc(64vw / 7.68), 76px);
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -1.52px;

  @media (max-width: 864px) {
    letter-spacing: -1.28px;
  }

  @media (max-width: 480px) {
    letter-spacing: 0.48px;
  }
}

h2,
.h2,
.h2 > * {
  font-size: clamp(28px, calc(52vw / 7.68), 64px);
  font-weight: 500;
  line-height: 115%;
  letter-spacing: -1.28px;

  @media (max-width: 864px) {
    line-height: 123%;
    letter-spacing: -1.04px;
  }

  @media (max-width: 480px) {
    line-height: 130%;
    letter-spacing: 0.42px;
  }
}

h3,
.h3,
.h3 > * {
  font-size: clamp(24px, calc(32vw / 7.68), 48px);
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.72px;

  @media (max-width: 864px) {
    line-height: 125%;
    letter-spacing: 0.48px;
  }

  @media (max-width: 480px) {
    line-height: 130%;
    letter-spacing: 0.36px;
  }
}

h4,
.h4,
.h4 > * {
  font-size: clamp(20px, calc(24vw / 7.68), 32px);
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.48px;

  @media (max-width: 864px) {
    line-height: 130%;
    letter-spacing: 0.36px;
  }

  @media (max-width: 480px) {
    line-height: 150%;
    letter-spacing: 0.3px;
  }
}

h5,
.h5,
.h5 > * {
  font-size: clamp(20px, calc(20vw / 7.68), 24px);
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.36px;

  @media (max-width: 864px) {
    line-height: 130%;
    letter-spacing: 0.3px;
  }

  @media (max-width: 480px) {
    line-height: 140%;
    letter-spacing: 0.3px;
  }
}

h6,
.h6 {
}

.bold {
  font-weight: 700;
}

.container {
  max-width: 1208px;
  width: calc(100% - clamp(32px, calc(80vw / 10.24), 160px));
  margin: 0 auto;
}

main {
  display: grid;
  gap: clamp(128px, calc(128vw / 7.68), 176px);
}

.container {
  max-width: 1208px;
  margin: 0 auto;
  width: calc(100% - clamp(32px, calc(120vw / 10.24), 160px));

  @media (max-width: 420px) {
    width: calc(100% - 32px);
  }
}

:root {
  --neutral-100: #ffffff;

  --yellow-200: #FDE4B3;
  --yellow-500: #f9bc41;
  --yellow-800: #6E521B;

  --light-blue-100: #f8fcff;
  --light-blue-200: #8EC3E5;
  --light-blue-500: #0376be;
  --light-blue-600: #025e98;
  --light-blue-800: #012f4c;

  --green-100: #f8fffd;
  --green-200: #8EE5CB;
  --green-500: #03be86;
  --green-800: #014C36;

  --yellow-500: #f9bc41;
  --yellow-100: #fffdf8;

  --navy-500: #273a78;
  --navy-600: #1f2e60;
  --navy-800: #101730;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

a {
  text-decoration: unset;
  color: #242424;
}

// article styles

.unorderedList {
  display: grid;
  gap: 30px;

  li {
    display: grid;
    grid-template-columns: 32px auto;
    gap: 15px;
  }
}

.orderedList {
  display: grid;
  gap: 30px;

  li {
    list-style: decimal;
    margin-left: 32px;
    padding-left: 16px;
  }
}

// select styles

.select {
  cursor: pointer;
  
  &.errored {
    .Dropdown-control {
      border: 1px solid var(--error-500, #d87e7e);
    }

    .Dropdown-menu {
      border: 1px solid var(--error-500, #d87e7e);
      border-top: none;
    }
  }

  .Dropdown-control {
    border-radius: 12px;
    border: 1px solid var(--light-blue-500, #0376be);
    background: var(--light-blue-100, #f8fcff);
    padding: 12px 16px;
    transition: border-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    .Dropdown-placeholder {
      color: var(--light-blue-600, #025e98);
      font-size: 16px;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: 0.24px;
    }
  }

  .Dropdown-menu {
    border-radius: 0 0 12px 12px;
    border: 1px solid var(--light-blue-500, #0376be);
    background: var(--light-blue-100, #f8fcff);
    padding: 0px 16px 6px 16px;
    border-top: none;
    transition: border 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    max-height: 300px;
  }

  .Dropdown-option {
    color: var(--light-blue-600, #025e98);
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.24px;
    padding: 6px 0;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    &:hover {
      text-decoration-color: var(--light-blue-600, #025e98);
      font-weight: 500;
    }

    &.is-selected {
      font-weight: 500;
      text-decoration-color: transparent !important ;
    }
  }

  &.is-open {
    .Dropdown-control {
      border-radius: 12px 12px 0 0;
    }

    .Dropdown-arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .Dropdown-arrow {
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    width: 28px;
    height: 29px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    &::after {
      content: url('./dropdown.svg');
      color: var(--light-blue-600, #025e98);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
      height: 29px;
    }
  }
}

.anim-link{
  background-image: linear-gradient(
    to right,
    #0376be,
    #0376be 50%,
    #000 50%
  );
  background-size: 200% 100%;
  background-position: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: background-position 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;

  &::before {
    content: '';
    background: #0376be;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &:hover, &:focus-within {
    background-position: 0;
   }
}