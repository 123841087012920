.wrapper {
  padding: 188px 0px 48px 0px;
  position: relative;
  overflow: hidden;

  > svg {
    z-index: -1;
    height: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas:
      'information links'
      'copyright links';

    gap: 80px 32px;

    @media (max-width: 864px) {
      grid-template-columns: 1fr;
      grid-template-areas: 'information' 'links' 'copyright';
      gap: 64px;
    }

    .information {
      grid-area: information;

      >a{
        display: block;
        margin-bottom: 24px;
      }

      @media (max-width: 864px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      @media (max-width: 480px) {
        display: block;
      }

      .logo {
        width: 187px;
        height: fit-content;
      }

      .content {
        max-width: 223px;

        h3 {
          margin-bottom: 12px;
        }

        p {
        }
      }
    }

    .copyright {
      grid-area: copyright;

      > p {
        display: flex;
        align-items: center;
        gap: 8px;

        a {
          height: 20px;
        }
      }
    }

    .links {
      grid-area: links;
      display: flex;
      gap: 48px 32px;
      margin-left: auto;
      width: fit-content;

      @media (max-width: 864px) {
        margin: 0;
        flex-wrap: wrap;
      }

      @media (max-width: 480px) {
        flex-direction: column;
        gap: 32px;
      }

      ul {
        display: flex;
        flex-direction: column;
        max-width: 160px;

        li {
          margin-top: 16px;

          &:first-child {
            margin-top: 0;
            margin-bottom: 8px;
          }

          &:last-child {
            margin-top: auto;

            @media (max-width: 864px) {
              padding-top: 80px;
              display: block;
            }

            @media (max-width: 480px) {
              padding-top: 48px;
            }

            a {
              font-weight: 700;
            }
          }

          a {
            display: block;
            width: fit-content;
          }
        }
      }
    }
  }
}
